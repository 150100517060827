import { render, staticRenderFns } from "./TabKvittoTable.vue?vue&type=template&id=3e4d4012&scoped=true"
import script from "./TabKvittoTable.vue?vue&type=script&lang=js"
export * from "./TabKvittoTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4d4012",
  null
  
)

export default component.exports