<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    
    <b-row class="ml-4 mr-4">
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="advance-file-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="current_page"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #head(name)="data">
          <tip-label :tip="$t('KVITTO.ACCOUNT.NAME_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(assigned_user_id)="data">
          <tip-label :tip="$t('KVITTO.ACCOUNT.ASSIGNED_USER_ID_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #cell(id)="row">
          <div class="justify-content-end d-flex">

            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_email_clicked(row.item.id)" v-b-tooltip="{ title: $t('PAGES.FILES.EDIT_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_email_clicked(row.item.id)" v-b-tooltip="{ title: $t('PAGES.FILES.DELETE_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>



</style>
<script>

import TipLabel from '@/view/components/TipLabel.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'IncomingEmaisTable',
  props: ['items', 'currentCompanyId'],
  emits: ['create_email_clicked', 'select_email_clicked', 'delete_email_clicked'],
  mixins: [ toasts ],
  components: { TipLabel },
  computed: {
    total_rows() {
      return this.items.length;
    }
  },
  methods: {
    create_email_clicked() {
      this.$emit('create_email_clicked');
    },
    select_email_clicked(email_id) {
      this.$emit('select_email_clicked', email_id);
    },
    delete_email_clicked(email_id) {
      this.$emit('delete_email_clicked', email_id);
    },
  },
  data() {
    return {
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'from',
          label: this.$t('KVITTO.INCOMING_EMAIL.FROM'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'original_email',
          label: this.$t('KVITTO.INCOMING_EMAIL.ORIGINAL_EMAIL'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7',
          /*formatter: (_, __, item) => {
            return item.assigned_user ? item.assigned_user.email : this.$t('KVITTO.NONE_ASSIGNED')
          }*/
        },
        {
          key: 'created_at',
          label: this.$t('PAGES.FILES.DATE'),
          sortable: true,
          thClass: 'w-150px',
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        
        {
          key: 'id',
          label: this.$t('PAGES.FILES.HANDLE'),
          thClass: 'w-150px text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
