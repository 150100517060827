<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a
          v-if="!show_connect"
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="create_kvitto_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('KVITTO.NEW_KVITTO') }}</a
        >
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <b-row class="ml-4 mr-4">
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <!--begin::Table-->

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="advance-kvitto-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="current_page"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #head(original_email)="data">
          <tip-label :tip="$t('KVITTO.ORIGINAL_EMAIL_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(title)="data">
          <tip-label :tip="$t('KVITTO.TITLE_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(created_at)="data">
          <tip-label :tip="$t('KVITTO.CREATED_AT_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #head(id)="data">
          <tip-label :tip="$t('PAGES.FILES.HANDLE_TIP')">{{ data.label }}</tip-label>
        </template>

        <template #cell(id)="row">
          <div class="justify-content-end d-flex">
            <a v-if="show_connect" class="btn btn-icon btn-light btn-sm mx-3" @click.prevent="connect_clicked(row.item)" v-b-tooltip="{ title: $t('KVITTO.CONNECT_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-link color-primary"></i>
              </span>
            </a>
            <a v-if="row.item.attached_file_id" href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="download_kvitto_clicked(row.item.attached_file_id)" v-b-tooltip="{ title: $t('PAGES.FILES.DOWNLOAD_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              </span>
            </a>
            <a v-if="row.item.generated_file_id" href="#" style="background-color: #c7ffa9;" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="download_kvitto_clicked(row.item.generated_file_id)" v-b-tooltip="{ title: $t('PAGES.FILES.DOWNLOAD_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              </span>
            </a>
            <a v-if="!show_connect" href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_kvitto_clicked(row.item.id)" v-b-tooltip="{ title: $t('PAGES.FILES.EDIT_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a v-if="!show_connect" href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_kvitto_clicked(row.item.id)" v-b-tooltip="{ title: $t('PAGES.FILES.DELETE_FILE_ICON_TIP'), placement: 'top', boundary: 'window', delay: 500, trigger: 'hover' }">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>



</style>
<script>

import TipLabel from '@/view/components/TipLabel.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {
  name: 'KvittoTable',
  props: ['items', 'show_connect'],
  emits: ['create_kvitto_clicked', 'download_kvitto_clicked', 'select_kvitto_clicked', 'delete_kvitto_clicked'],
  mixins: [ toasts ],
  computed: {
    total_rows() {
      return this.items.length;
    }
  },
  components: { TipLabel },
  methods: {
    create_kvitto_clicked() {
      this.$emit('create_kvitto_clicked');
    },
    download_kvitto_clicked(kvitto_id) {
      this.$emit('download_kvitto_clicked', kvitto_id);
    },
    select_kvitto_clicked(kvitto_id) {
      this.$emit('select_kvitto_clicked', kvitto_id);
    },
    delete_kvitto_clicked(kvitto_id) {
      this.$emit('delete_kvitto_clicked', kvitto_id);
    },
    connect_clicked(kvitto) {
      this.$emit('connect', kvitto);
    },

  },
  data() {
    return {
      
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'original_email',
          label: this.$t('KVITTO.ORIGINAL_EMAIL'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'title',
          label: this.$t('KVITTO.TITLE'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'amount',
          label: this.$t('KVITTO.AMOUNT'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return ((item.amount || 0) / 100) + (item.currency || '?');
          }
        },
        {
          key: 'created_at',
          label: this.$t('PAGES.FILES.DATE'),
          sortable: true,
          thClass: 'w-150px',
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss');
          }
        },
        
        {
          key: 'id',
          label: this.$t('PAGES.FILES.HANDLE'),
          thClass: 'w-150px text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
