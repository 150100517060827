<template>
  <div>
    <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" />

    <KvittoRulesTable
      :items="rules"
      @create_rule_clicked="create_rule_clicked"
      @select_rule_clicked="select_rule_clicked"
      @delete_rule_clicked="delete_rule_clicked"
    />

    <KvittoRuleEditorModal
      :item="rule"
      ref="rule-editor-modal"
      @updated="rule_updated"
      @created="rule_created"
      
    />

  </div>
  
</template>


<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';

import KvittoRulesTable from '@/view/pages/ml/kvitto/KvittoRulesTable.vue';
import KvittoRuleEditorModal from '@/view/pages/ml/kvitto/KvittoRuleEditorModal.vue';

export default {
  name: 'TabKvittoRules',
  mixins: [ toasts ],
  components: {
    KvittoRulesTable,
    KvittoRuleEditorModal,
    TipLabel,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'customer'])
  },
  mounted() {
    this.fetch_rules(this.currentCompanyId);
  },
  data() {
    return {
      selected_rule_id: null,
      rule: {},
      rules: [],
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.fetch_rules(this.currentCompanyId);
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {}
    }
  },
  methods: {
    
    rule_updated(rule) {
      const index = this.rules.findIndex(item => item.id === rule.id);

      if (index < 0) {
        return;
      }

      this.rules[index] = rule;

      this.rules = [ ...this.rules ];
    },

    rule_created(rule) {
      this.rules.unshift(rule);
    },

    create_rule_clicked() {
      this.$refs['rule-editor-modal'].show();
    },

    async select_rule_clicked(rule_id) {
      const loader = this.$loading.show();
      
      this.selected_rule_id = rule_id;
      this.rule = this.rules.find(item => item.id === rule_id);
      this.$refs['rule-editor-modal'].show();

      loader && loader.hide();
    },

    async delete_rule_clicked(rule_id) {
      const res = await axios.delete(`/kvitto/rule/${rule_id}`);

      if (res.status === 204) {
        this.rules = this.rules.filter(item => item.id !== rule_id);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('KVITTO_RULE.DELETED'));
      }
    },

    async fetch_rule(rule_id) {
      const res = await axios.get(`/kvitto/rule/${rule_id}`);

      if (res.status === 200) {
        this.rule = res.data;
      }
      
    },

    async fetch_rules(company_id) {
      const res = await axios.get(`/kvitto/rule/company/${company_id}`);

      if (res.status === 200) {
        this.rules = res.data;
      }
      
    },

  }
};
</script>
