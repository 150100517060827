<!-- Kvitto main page -->
<template>
  <div id="page-kvitto">
    <h5 class="m-4">{{ $t('KVITTO.HEADER') }}</h5>

    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{$t('KVITTO.MY_LIST')}}</v-tab>
      <v-tab>{{$t('KVITTO.FILES')}}</v-tab>
      <v-tab>{{$t('KVITTO.RECEIPTS')}}</v-tab>
      <v-tab>{{$t('KVITTO.INCOMING_EMAILS')}}</v-tab>
      <v-tab>{{$t('KVITTO.KVITTO_RULES')}}</v-tab>
      <v-tab>{{$t('KVITTO.ACCOUNTS')}}</v-tab>
      <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

      <v-tab-item>
        <TabMyList />
      </v-tab-item>

      <v-tab-item>
        <TabTransactionFilesTable />
      </v-tab-item>

      <v-tab-item>
        <TabKvittoTable />
      </v-tab-item>

      <v-tab-item>
        <TabIncomingEmails />
      </v-tab-item>

      <v-tab-item>
        <TabKvittoRules />
      </v-tab-item>

      <v-tab-item>
        <TabAccountsTable />
      </v-tab-item>
      
      <v-tab-item>
        <TabSettings />
      </v-tab-item>

    </v-tabs>

  </div>
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import TabTransactionFilesTable from '@/view/pages/ml/kvitto/TabTransactionFilesTable.vue';
import TabKvittoTable from '@/view/pages/ml/kvitto/TabKvittoTable.vue';
import TabAccountsTable from '@/view/pages/ml/kvitto/TabAccountsTable.vue';
import TabMyList from '@/view/pages/ml/kvitto/TabMyList.vue';
import TabIncomingEmails from '@/view/pages/ml/kvitto/TabIncomingEmails.vue';
import TabSettings from '@/view/pages/ml/kvitto/TabSettings.vue';
import TabKvittoRules from '@/view/pages/ml/kvitto/TabKvittoRules.vue';


export default {
  name: 'KvittoPage',
  mixins: [ toasts ],
  components: {
    TabTransactionFilesTable,
    TabKvittoTable,
    TabAccountsTable,
    TabMyList,
    TabIncomingEmails,
    TabSettings,
    TabKvittoRules
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    },
    currentPeriodId(newValue, oldValue) {
      
    }
  },
  methods: {

  }
};
</script>
